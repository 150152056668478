import React, {FunctionComponent} from 'react';
import {navigate} from 'gatsby';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import CardMenu from '../../components/card-menu';
import {Translate} from '../../components/translations';
import {AccountProps} from '../../types';
import UserWrap from '../../components/userWrap';
import Layout from '../../components/layout/layout';
import Section from '../../components/section';
import './styles.scss';

const Account: FunctionComponent<AccountProps> = (props): JSX.Element => {
  const MenuCardItems = [
    {
      text: <Translate name="UPDATE_INFORMATION" />,
      to: '/account',
    },
    {
      text: <Translate name="RESET_PASSWORD" />,
      to: '/account/change-password',
    },
  ];

  return (
    <Layout>
      <Section className="user-info">
        <UserWrap
          withLoading={() => (
            <div className="col-md-12 text-center">
              <FontAwesomeIcon spin icon={faSpinner} size="2x" />
            </div>
          )}
          withUser={user => (
            <>
              <div className="col-lg-4">
                <div className="left-sidebar">
                  <CardMenu menuItems={MenuCardItems}>
                    <div className="account-info row">
                      {user?.email && (
                        <div className="col-md-3">
                          <img alt="" src='https://hexometer.com/avatar-hexometer.svg' />
                        </div>
                      )}
                      <div className="acc-right-info col-md-9">
                        <p className="user-name f_size_15 mb-0">
                          {user.firstName} {user.lastName}
                        </p>
                        <p className="user-email f_size_15">{user.email}</p>
                      </div>
                    </div>
                  </CardMenu>
                </div>
              </div>
            </>
          )}
          withoutUser={() => {
            navigate('/#login', {state: {goBack: true}});
            return <></>;
          }}
        />
        <div className="col-lg-8 d-flex align-items-center">
          <div className="user-content">
            <h3 className="user-content-title f_300 f_size_30 ">{props.title}</h3>
            {props.children}
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default Account;
