import React, {FC, useState} from 'react';
import {navigate} from 'gatsby';
import {useMutation} from '@apollo/client';

import {Form, FormItem, Input} from '../../../components/form';
import {Translate} from '../../../components/translations';
import Account from '../../../components/accountWrapper';
import Button from '../../../components/button';

import {USER_UPDATE_PASSWORD} from '../../../graphql/mutations';
import {UpdateUserProps, UserUpdatePasswordState, UpdatePasswordMutationData} from '../../../types';

const UpdateUser: FC<UpdateUserProps> = () => {
  const [updatePasswordMutation, {loading}] = useMutation<UpdatePasswordMutationData>(USER_UPDATE_PASSWORD);

  const [formData, setFormData] = useState<UserUpdatePasswordState>({
    password: '',
    repeatPassword: '',
    error: false,
    errorMessage: '',
    oldPassword: '',
  });

  const fieldChangeHandler = (event: React.SyntheticEvent): void => {
    const target = event.target as HTMLInputElement;
    const {value, name} = target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submitHandler = async () => {
    setFormData({...formData, error: false, errorMessage: ''});
    const {data} = await updatePasswordMutation({
      variables: {
        ...formData,
      },
    });
    if (data) {
      const {
        UserOps: {
          updatePassword: {error, message},
        },
      } = data;
      if (error) {
        setFormData({...formData, error: true, errorMessage: message});
      } else {
        navigate('/');
      }
    }
  };

  return (
    <Account title={<Translate name="RESET_PASSWORD_TITLE" />}>
      <Form className="mb_70 update-form" onSubmit={submitHandler}>
        <FormItem>
          <Translate name="OLD_PASSWORD">
            {text => (
              <Input
                style={
                  formData.error && formData.errorMessage === 'INVALID_PASSWORD'
                    ? {border: '1px', borderColor: 'red', borderStyle: 'solid'}
                    : {}
                }
                required
                placeholder={text}
                type="password"
                name="oldPassword"
                onChange={fieldChangeHandler}
                value={formData.oldPassword}
              />
            )}
          </Translate>
        </FormItem>
        <FormItem>
          <Translate name="PASSWORD">
            {text => (
              <Input
                style={
                  formData.error && formData.errorMessage !== 'INVALID_PASSWORD'
                    ? {border: '1px', borderColor: 'red', borderStyle: 'solid'}
                    : {}
                }
                required
                placeholder={text}
                type="password"
                name="password"
                onChange={fieldChangeHandler}
                value={formData.password}
              />
            )}
          </Translate>
        </FormItem>
        <FormItem>
          <Translate name="REPEAT_PASSWORD">
            {text => (
              <Input
                style={
                  formData.error && formData.errorMessage !== 'INVALID_PASSWORD'
                    ? {border: '1px', borderColor: 'red', borderStyle: 'solid'}
                    : {}
                }
                required
                type="password"
                name="repeatPassword"
                onChange={fieldChangeHandler}
                value={formData.repeatPassword}
                placeholder={text}
                maxLength={72}
              />
            )}
          </Translate>
        </FormItem>
        {formData.error && (
          <div className="error-message">
            <Translate name={formData.errorMessage} />
          </div>
        )}
        <Button primary className="btn_three" loading={loading}>
          <Translate name="RESET_PASSWORD_BUTTON" />
        </Button>
      </Form>
    </Account>
  );
};

export default UpdateUser;
