import React, {FunctionComponent, useState} from 'react';
import { Link } from 'gatsby';
import {
  CardMenuProps,
  CardMenuItem,
  locationFields,
} from "../../types";

import './styles.scss';
import {Translate} from "../translations";

const CardMenu: FunctionComponent<CardMenuProps> = (props: CardMenuProps) => {

  const [ activeItem, setState ] = useState('');

  const generateMenu = (
    items: Array<CardMenuItem>,
    activeItem: string,
    location?: locationFields
  ): Array<JSX.Element> => items.map((item: CardMenuItem): JSX.Element =>
    item.href
      ? (
        <li
          key={item.to || item.href}
          className="nav-item"
        >
          <a
            className={`${activeItem === item.href
              ? 'nav-link show'
              : 'nav-link'
              }`}

            id="care-tab"
            onClick={() => item.href && clickHandler(item.href)}
            data-toggle="tab"
            href={item.href}
            role="tab"
            aria-controls="care"
            aria-selected="false"
            data-testid="nav-link-href"
          >
            { item.text }
          </a>
        </li>
      )
    : (
      <li
        key={item.to}
        className="nav-item"
      >
        <Link
          className={`${location && item.to === location.pathname
            ? 'nav-link active show'
            : 'nav-link'
            }`}
          data-testid="nav-link-to"
          to={item.to || ''}
        >
          {item.text}
        </Link>
      </li>
    )
  );

  const clickHandler = (activeItem: string): void => setState(
    activeItem
  );

  return (
    <div className="faq_tab">
      {props.children}
      {
        props.title && (<h4 className="f_p t_color3 f_600 f_size_22 mb_40" data-testid="card-menu-title">{typeof props.title === 'string'
          ? <Translate name={props.title}/>
          : props.title
        }</h4>)
      }

      <ul
        className="nav nav-tabs"
        id="myTab"
        role="tablist"
      >
        {generateMenu(props.menuItems, activeItem, props.location)}
      </ul>
    </div>
  );
};

export default CardMenu;
